import { UserService } from 'src/app/shared/services/user.service';
import { HealthService } from './../shared/services/health.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Component({
  selector: 'app-health-benefit',
  templateUrl: './health-benefit.component.html',
  styleUrls: ['./health-benefit.component.scss']
})
export class HealthBenefitComponent implements OnInit {

  public showLoader:boolean = true;
  public windowWidth:any;
  beneficiaryDetails:any;
  cardId:any;
  personalNo:any;
  openedArray: boolean[]= [false];
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.windowWidth = event.target.innerWidth;
  }
  
  constructor(private healthService: HealthService, private userService: UserService, private localStorageService:LocalStorageService) { 
    this.personalNo = this.localStorageService.get('personal_number');
  }

  openClaim(index) {
    this.openedArray[index] = !this.openedArray[index]
    this.openedArray = this.openedArray.map((el, i) => {
      if(i != index) el = false;
      return el;
    });
  }
  
  setBackgroundColor(index){
      if (index % 2 == 0) 
        return '#FFFFFF';
      else 
        return '#F8F9FA';
  }

  initializeData(){
    this.userService.getHealthInsurance(this.personalNo).subscribe(data =>{
      this.cardId = data['cardId'];
      this.healthService.getBenefits(this.cardId).subscribe(result => {
        this.beneficiaryDetails=result;
        this.openedArray.length = this.beneficiaryDetails.length;
        this.openedArray.fill(false);
        this.showLoader = false;
      });
    });
  }
  
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.initializeData();
  }

}
