import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  public baseURL1="https://api.eurosig-ks.com/apiEurosig/api/accounts";
  public headers:HttpHeaders;
  public personalNo:any;

  constructor(private http:HttpClient, private localStorageService:LocalStorageService, private router:Router) { }

  setHeaders(){
    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', 'Bearer ' + this.localStorageService.get('token'));
  }

 getPolicatTPL(allSelected, platesSelected, plates, activeSelected) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_PolicatTPL1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${platesSelected}/${plates}/false/''/${activeSelected}`, {'headers':this.headers})
 }
 getPolicatTPLPlus(allSelected, platesSelected, plates, activeSelected) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_PolicatTPLPlus1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${platesSelected}/${plates}/false/''/${activeSelected}`, {'headers':this.headers})
 }
 getPolicatCasco(allSelected, platesSelected, plates, activeSelected) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_PolicatCasco1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${platesSelected}/${plates}/false/''/${activeSelected}`, {'headers':this.headers})
 }
 getPolicatTjera(allSelected, activeSelected, type) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_PolicatTjera1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${type}/${activeSelected}`, {'headers':this.headers})
 }
 getLlojetSigurimitPerPerson(){
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_LlojetSigurimit1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}`, {'headers':this.headers})
 }
 getPolicatFundit(activeSelected, numberOfProducts){
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_Policat1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${activeSelected}/${numberOfProducts}`, {'headers':this.headers})
 }
}
