import { ClaimsService } from './../shared/services/claims.service';
import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ProductsService } from '../shared/services/products.service';

@Component({
  selector: 'app-tpl-claim',
  templateUrl: './tpl-claim.component.html',
  styleUrls: ['./tpl-claim.component.scss']
})
export class TplClaimComponent implements OnInit {

  public showLoader:boolean = true;
  public windowWidth:any;
  tplClaimsDetails:any;
  activeSelected = true;
  allSelected = false;
  platesSelected = false;
  plateInput:any=null;
  openedArray: boolean[]= [false];
  openedCarDetails:boolean=false;
  openedReviewDetails:boolean=false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.windowWidth = event.target.innerWidth;
  }

  constructor(private claimService: ClaimsService) { }

  openClaim(index) {
    this.openedArray[index] = !this.openedArray[index]
    this.openedArray = this.openedArray.map((el, i) => {
      if(i != index) el = false;
      return el;
    });
    this.openedCarDetails = false;
    this.openedReviewDetails = false;
  }
  
  setBackgroundColor(index){
      if (index % 2 == 0) 
        return '#FFFFFF';
      else 
        return '#F8F9FA';
  }

  selectChanged(checked){
    if(checked == true) {
      this.activeSelected = true;
      this.allSelected = false;
    }
    else {
      this.activeSelected = false;
      this.allSelected = true;
    }
    this.initializeData();
  }

  onSearchChange(){
    if(this.plateInput.length > 0) {
      this.platesSelected = true;
    }
    else {
      this.platesSelected = false;
      this.plateInput = null;
    }
    this.initializeData();
  }

  initializeData(){
    this.claimService.getDemetTPL(26, this.allSelected, this.platesSelected, this.plateInput, this.activeSelected).subscribe(result => {
      this.tplClaimsDetails = result;
      this.tplClaimsDetails.map(element => {
        element.statusi_lendes = element.statusi_lendes.split(",")
      })
      this.openedArray.length = this.tplClaimsDetails.length
      this.openedArray.fill(false)
      this.showLoader = false;
    })
  }

  formatDate(date){
    return moment(date).format('DD/MM/YYYY');
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.initializeData();
  }

}
