import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  public baseURL1="https://api.eurosig-ks.com/apiEurosig/api/accounts";
  public headers:HttpHeaders;
  public personalNo:any;

  constructor(private http:HttpClient, private localStorageService:LocalStorageService, private router: Router) { }

  setHeaders(){
    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', 'Bearer ' + this.localStorageService.get('token'));
  }

 getLlojetSigurimitPerPerson(){
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_LlojetSigurimit1Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}`, {'headers':this.headers})
 }
 getDemetCasco(allSelected, platesSelected, plates, activeSelected) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_Demet_Casco_Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${platesSelected}/${plates}/false/''/${activeSelected}`, {'headers':this.headers})
 }
 getDemetTPL(typeId, allSelected, platesSelected, plates, activeSelected) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_Demet_TPL_Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${platesSelected}/${plates}/false/''/${activeSelected}/false/${typeId}`, {'headers':this.headers})
 }
 getDemetSigurimetTjera(type, allSelected, activeSelected) {
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_Demet_Tjera_Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${allSelected}/${type}/${activeSelected}/`, {'headers':this.headers})
 }
 getDemetFundit(unClosedClaims){
  this.setHeaders();
  this.personalNo = this.localStorageService.get('personal_number');
  let funksioni = "Get_Demet_Person";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${this.personalNo}/${unClosedClaims}`, {'headers':this.headers})
 }
}
