import { UserAccountComponent } from './user-account/user-account.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LoginComponent } from './user-pages/login/login.component';
import { HealthBenefitAccidentComponent } from './health-benefit-accident/health-benefit-accident.component';
import { HealthTerritoriesCoverageComponent } from './health-territories-coverage/health-territories-coverage.component';
import { HealthBenefitComponent } from './health-benefit/health-benefit.component';
import { HealthClaimsComponent } from './health-claims/health-claims.component';
import { CascoClaimComponent } from './casco-claim/casco-claim.component';
import { OthersClaimComponent } from './others-claim/others-claim.component';
import { TplClaimComponent } from './tpl-claim/tpl-claim.component';
import { TplPlusProductComponent } from './tpl-plus-product/tpl-plus-product.component';
import { TplPlusClaimComponent } from './tpl-plus-claim/tpl-plus-claim.component';
import { CascoProductComponent } from './casco-product/casco-product.component';
import { OthersProductComponent } from './others-product/others-product.component';
import { TplProductComponent } from './tpl-product/tpl-product.component';
import { OldDashboardComponent } from './old-dashboard/old-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthorizeGuardGuard as AuthGuard } from './shared/guards/authorize-guard.guard';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'user-account', component: UserAccountComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/home', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/products/tpl', component: TplProductComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/products/tpl-plus', component: TplPlusProductComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/products/casco', component: CascoProductComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/products/others', component: OthersProductComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/claims/tpl', component: TplClaimComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/claims/tpl-plus', component: TplPlusClaimComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/claims/casco', component: CascoClaimComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/claims/others', component: OthersClaimComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/healthInsurance/claims', component: HealthClaimsComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/healthInsurance/benfit-health', component: HealthBenefitComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/healthInsurance/benefit-accidents', component: HealthBenefitAccidentComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/healthInsurance/covering-territories', component: HealthTerritoriesCoverageComponent, canActivate: [AuthGuard] },
  { path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  { path: 'forms', loadChildren: () => import('./forms/form.module').then(m => m.FormModule) },
  { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
  { path: 'user-pages', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule) },
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
