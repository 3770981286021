import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  public baseURL1="https://api.eurosig-ks.com/apiEurosig/api/accounts";
  public baseURL2="https://api.eurosig-ks.com/apiEurosig/api/Shendeti";

  constructor(private http:HttpClient, private localStorageService:LocalStorageService, private router:Router) {
   }

  headers= new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('API_KEY', '24F3DBAC');

  getRequestsForClaims(cardId, date1, date2) {
    let funksioni = "HealthInsKerkesatPerKartele";
    return this.http.get<any>(`${this.baseURL2}/${funksioni}/${cardId}/${date1}/${date2}`, {'headers':this.headers})
  }
  getFamiljaretPerKartele(userId){
    let funksioni = "GetFamiljaretPerKartele";
    return this.http.get<any>(`${this.baseURL2}/${funksioni}/${userId}`,  {'headers':this.headers} )
  }
  getCoverageTerritories(cardId) {
    let funksioni = "HealthInsTerritories";
    return this.http.get<any>(`${this.baseURL2}/${funksioni}/${cardId}/sq`, {'headers':this.headers})
  }
  getBenefits(cardId) {
    let funksioni = "HealthInsBeneficiaries";
    return this.http.get<any>(`${this.baseURL2}/${funksioni}/${cardId}/sq`, {'headers':this.headers})
  }
  getBenefitsForAccidets(cardId) {
    let funksioni = "HealthInsAccidentBeneficiaries";
    return this.http.get<any>(`${this.baseURL2}/${funksioni}/${cardId}/sq`, {'headers':this.headers})
  }

}
