import { LoginComponent } from './user-pages/login/login.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './shared/material.module';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TodoComponent } from './apps/todo-list/todo/todo.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TodoListComponent } from './apps/todo-list/todo-list.component';
import { OldDashboardComponent } from './old-dashboard/old-dashboard.component';
import { TplProductComponent } from './tpl-product/tpl-product.component';
import { CascoProductComponent } from './casco-product/casco-product.component';
import { TplPlusProductComponent } from './tpl-plus-product/tpl-plus-product.component';
import { OthersProductComponent } from './others-product/others-product.component';
import { OthersClaimComponent } from './others-claim/others-claim.component';
import { TplPlusClaimComponent } from './tpl-plus-claim/tpl-plus-claim.component';
import { CascoClaimComponent } from './casco-claim/casco-claim.component';
import { TplClaimComponent } from './tpl-claim/tpl-claim.component';
import { SharedService } from './shared/services/shared.service';
import { ClaimsService } from './shared/services/claims.service';
import { ProductsService } from './shared/services/products.service';
import { LoginService } from './shared/services/login.service';
import { JWTTokenService } from './shared/services/jwttoken.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { HttpClientModule } from '@angular/common/http';
import { HealthClaimsComponent } from './health-claims/health-claims.component';
import { HealthBenefitComponent } from './health-benefit/health-benefit.component';
import { HealthBenefitAccidentComponent } from './health-benefit-accident/health-benefit-accident.component';
import { HealthTerritoriesCoverageComponent } from './health-territories-coverage/health-territories-coverage.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserAccountComponent } from './user-account/user-account.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    TodoListComponent,
    TodoComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    OldDashboardComponent,
    TplProductComponent,
    CascoProductComponent,
    TplPlusProductComponent,
    OthersProductComponent,
    OthersClaimComponent,
    TplPlusClaimComponent,
    CascoClaimComponent,
    TplClaimComponent,
    HealthClaimsComponent,
    HealthBenefitComponent,
    HealthBenefitAccidentComponent,
    HealthTerritoriesCoverageComponent,
    LoginComponent,
    UserProfileComponent,
    UserAccountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [SharedService, ProductsService, ClaimsService, LoginService, JWTTokenService, LocalStorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
