import { LocalStorageService } from './../shared/services/local-storage.service';
import { UserService } from './../shared/services/user.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  public showLoader:boolean = true;
  dataChanged:boolean = false;
  buttonClicked:boolean = false;
  countries:any;
  userData:any;
  agreed:boolean;
  fullName:string;

  constructor(private userService: UserService, private localStorageService: LocalStorageService, private snackBar: MatSnackBar) { }

  inputChange(){
    this.dataChanged = true;
  }

  kosovaSelected(event){
    if(event == true) {
      this.userData.Citizenship = 40;
    }
  }

  saveUserProfileChanges() {
    this.buttonClicked = true;
    if(this.agreed && this.fullName){
      let user = (({ FirstName, PersonalNo, PassportNo, BirthDate, Gender, Citizenship, Municipality, Address, IsLegalPerson, Kosove, PhoneNumber }) => ({ FirstName, PersonalNo, PassportNo, BirthDate, Gender, Citizenship, Municipality, Address, IsLegalPerson, Kosove, PhoneNumber }))(this.userData);
      this.userService.changeUserProfileDetails(user).subscribe(result =>{ 
        this.snackBar.open("Të dhënat e profilit janë përditësuar me sukses!", "Mbylle", {
          duration: 4000,
        });
      });
    }
    else {
      this.snackBar.open("Të dhëna jo valide", "Mbylle", {
        duration: 4000,
      });
    }
  }

  getCountries(){
    this.userService.getCountries('sq').subscribe( result => {
      this.countries = result;
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  ngOnInit(): void {
    this.getCountries();
    this.userService.getUserByUsername(this.localStorageService.get('unique_name')).subscribe(result =>{
      this.userData = (({ UserName, FirstName, PersonalNo, PassportNo, Email, BirthDate, Gender, Citizenship, Municipality, Address, IsLegalPerson, Kosove, PhoneNumber }) => ({UserName, FirstName, PersonalNo, PassportNo, Email, BirthDate, Gender, Citizenship, Municipality, Address, IsLegalPerson, Kosove, PhoneNumber }))(result);
        this.fullName = this.userData['FirstName']
        if(this.userData['Kosove'] == true || this.userData['Kosove'] == 'true'){
          this.userData['Citizenship'] = 40;
        }
        this.showLoader = false;
    });
  }

}
