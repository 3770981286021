import { ClaimsService } from './../shared/services/claims.service';
import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ProductsService } from '../shared/services/products.service';

@Component({
  selector: 'app-others-claim',
  templateUrl: './others-claim.component.html',
  styleUrls: ['./others-claim.component.scss']
})
export class OthersClaimComponent implements OnInit {

  public showLoader:boolean = true;
  public windowWidth:any;
  othersClaimsDetails:any;
  activeSelected = true;
  allSelected = false;
  productTypes:any=[];
  productTypeChosen = 0;
  openedArray: boolean[]= [false];
  openedCarDetails:boolean=false;
  openedPolicyDetails:boolean=false;
  openedReviewDetails:boolean=false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.windowWidth = event.target.innerWidth;
  }

  constructor(private claimService: ClaimsService) { }

  openClaim(index) {
    this.openedArray[index] = !this.openedArray[index]
    this.openedArray = this.openedArray.map((el, i) => {
      if(i != index) el = false;
      return el;
    });
    this.openedCarDetails = false;
    this.openedPolicyDetails = false;
  }
  
  setBackgroundColor(index){
      if (index % 2 == 0) 
        return '#FFFFFF';
      else 
        return '#F8F9FA';
  }

  changeProductChosen(){
    this.initializeData();
  }

  selectChanged(checked){
    if(checked == true) {
      this.activeSelected = true;
      this.allSelected = false;
    }
    else {
      this.activeSelected = false;
      this.allSelected = true;
    }
    this.initializeData();
  }

  setInsuranceTypeIcon(type){
    switch(type) { 
      case 'Sigurimi i Pronës nga Zjarri dhe rreziqet plotësuese': { 
         return '../../assets/images/propertyIcon-blue.png'
      }
      case 'Sigurimi i Pronës nga të gjitha rreziqet': { 
        return '../../assets/images/propertyIcon-blue.png'
     } 
      case 'Sigurimi nga Aksidentet Personale - Sigurimi individual': { 
         return '../../assets/images/accidentIcon-blue.png'
      }
      case 'Sigurimi nga Aksidentet Personale - Sigurimi ne Grup': { 
         return '../../assets/images/accidentIcon-blue.png'
      } 
      case 'Sigurimi i Kontraktuesve nga të gjitha rreziqet në ndërtim': { 
         return '../../assets/images/workerIcon-blue.png'
      } 
      case 'Sigurimi i Garancionit të Ofertës': { 
         return '../../assets/images/bidIcon-blue.png'
      }
      case 'Sigurimi i Përgjegjësisë Publike dhe të Produktit': { 
         return '../../assets/images/productIcon-blue.png'
      }
      case 'Sigurimi i Garancionit të Kontratës': { 
         return '../../assets/images/contractIcon-blue.png'
      }
      case 'Sigurimi i Parave në Kasafortë': { 
         return '../../assets/images/safeDepositIcon-blue.png'
      }   
      case 'Sigurimi i Shëndetit në Udhëtim - Koris': { 
         return '../../assets/images/travelIcon-blue.png'
      }      
      case 'Sigurimi i Përgjegjësisë Profesionale': { 
         return '../../assets/images/professionalLiabilityIcon-blue.png'
      } 
      case 'Sigurimi i Garancionit të Avansit-Doganore-Leje Ndertimi-Pagese': { 
         return '../../assets/images/defaultIcon-blue.png'
      }
      case 'Sigurimi i Shëndetit - Sigurimi në Grup': { 
         return '../../assets/images/healthIcon-blue.png'
      } 
      case 'Sigurimi i Shëndetit - Sigurimi Familjar': { 
         return '../../assets/images/healthIcon-blue.png'
      } 
      case 'Sigurimi i Shëndetit - Sigurimi Individual': { 
         return '../../assets/images/healthIcon-blue.png' 
      } 
      case 'Sigurimi i Ruajtjes/Mbajtjes': { 
         return '../../assets/images/contractIcon-blue.png'
      } 
      case 'Sigurimi i Mallërave gjatë transportit - Kargo': { 
         return '../../assets/images/cargoIcon-blue.png'
      } 
      case 'Sigurimi Shtëpiak': { 
         return '../../assets/images/houseIcon-blue.png'
      } 
      case 'Sigurimi i Shëndetit në Udhëtim': { 
         return '../../assets/images/travelIcon-blue.png'
      } 
      case 'Sigurimi i Parave gjatë transportit': { 
         return '../../assets/images/cashIcon-blue.png'
      }  
      case 'BBB & ECC': { 
         return '../../assets/images/defaultIcon-blue.png'
      }   
      default: { 
         return '../../assets/images/defaultIcon-blue.png'
      } 
   } 
  }

  initializeData(){
    this.claimService.getDemetSigurimetTjera(this.productTypeChosen, this.allSelected, this.activeSelected).subscribe(result => {
      this.othersClaimsDetails = result;
      this.othersClaimsDetails.map(element => {
        element.statusi_lendes = element.statusi_lendes.split(",")
      })
      this.openedArray.length = this.othersClaimsDetails.length
      this.openedArray.fill(false)
      this.showLoader = false;

    })
  }

  formatDate(date){
    return moment(date).format('DD/MM/YYYY');
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.claimService.getLlojetSigurimitPerPerson().subscribe(result => {
      result.map(product =>{
        if(product['llojisig_id'] != 1 && product['llojisig_id'] != 26 && product['llojisig_id'] != 27){
          this.productTypes.push(product);
        }
      })
      this.productTypes.unshift({"llojisig_id": 0, "lloji_sigurimit": 'Të gjitha'});
    })
    this.initializeData();
  }

}
