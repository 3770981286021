import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  baseURL1="https://api.eurosig-ks.com/apiEurosig/api/accounts/";

  constructor(private http:HttpClient, private router: Router, private localStorageService: LocalStorageService) { 
  }

  headers= new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${this.localStorageService.get('token')}`);

}
