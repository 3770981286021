import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  public loggedInUser:any;
  
  constructor(config: NgbDropdownConfig, private userService: UserService, private localStorageService: LocalStorageService, private router: Router) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
      this.userService.getUserByUsername(this.localStorageService.get('unique_name')).subscribe(result => {
        this.loggedInUser = result['FirstName'].trim();
        this.localStorageService.set('personal_number', result['PersonalNo']);
      });
  }

  logOut(){
    this.localStorageService.removeAll();
    this.router.navigate(['/'])
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

}
