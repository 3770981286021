import { AppComponent } from './../../app.component';
import { JWTTokenService } from './../../shared/services/jwttoken.service';
import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from '../../shared/services/login.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DOCUMENT } from '@angular/common';

interface user {
  cardHolder: string;
  cardId: string;
  change: boolean;
  lloji:number;
  passwordMatch:boolean;
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  hide1: boolean = true;
  hide2: boolean = true;
  hide3: boolean = true;
  hide4: boolean = true;
  invalidLogin: boolean;
  serverError: boolean;

  country = "Kosova";
  userType = "individ";
  email:any;
  password:any;
  nameSurname:any;
  personalNumber:any;
  agree:any;
  
  clicked:boolean=false;
  forgotPassword:boolean=false;

  clickFunction(){
    this.clicked = true;
  }

  inputChanged(){
    this.invalidLogin = false;
  }
  goToHealthLogin() {
    this.document.location.href = 'https://www.eurosig-ks.com/login';
  }

  goToHomePage() {
    this.document.location.href = 'https://www.eurosig-ks.com';
  }

  constructor(@Inject(DOCUMENT) private document: Document, private loginService: LoginService, private jwtTokenService: JWTTokenService, private localStorageService: LocalStorageService, private userService:UserService, private app:AppComponent, private router: Router, private snackBar: MatSnackBar) {
    if(!(this.jwtTokenService.isTokenExpired()) && !(this.localStorageService.get("token") === null)){
      this.router.navigate(['dashboard/home']);
    }
  }

  ngOnInit(): void {
  }

  signIn(credentials) {
    if(credentials.userName !== "" && credentials.loginPassword !== "") {
    let userCredentials = `grant_type=password&username=${credentials.userName}&password=${credentials.loginPassword}`
    this.loginService.logIn(userCredentials).subscribe(result => {
    this.localStorageService.set('token', result.access_token);
    this.jwtTokenService.setToken(result.access_token);
    this.localStorageService.set('nameid', this.jwtTokenService.getDecodeToken()['nameid']);
    this.localStorageService.set('unique_name', this.jwtTokenService.getDecodeToken()['unique_name']);
    this.localStorageService.set('expires_in', this.jwtTokenService.getDecodeToken()['exp']);
      this.userService.getUserByUsername(this.localStorageService.get('unique_name')).subscribe(result => {
        this.localStorageService.set('personal_number', result['PersonalNo']);
      });
    this.app.showFooter=true;
    this.app.showNavbar=true;
    this.app.showSidebar=true;
    document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      if(result['access_token']) {
        this.router.navigate(['dashboard/home'])
      }
      else {
        this.invalidLogin = true;
      }
}, (error) => {
  if(error.error.error == 'çasje jovalide' || error.status == 400) {
    this.snackBar.open('Të dhëna jo valide!', 'Mbylle'), {
      duration: 4000,
    };
  }
  else {
    this.snackBar.open('Ky shërbim momentalisht nuk është në dizpozicion. Provojeni sërish pas disa minutash.', 'Mbylle'), {
      duration: 4000,
    };
  }
})
}
  else {
      this.snackBar.open('Të dhëna jo valide!', 'Mbylle', {
      duration: 4000,
      });
  }
}

validEmail(email){
  let regexEmail= new RegExp('[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+');
  if(email) {
  return !regexEmail.test(email)
  }
}

validPassword(password){
  let regexPassword= new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$');
  if(password) {
  return !regexPassword.test(password)
  }
}

capitalizeName(){
  this.nameSurname = this.capitalizeCaseWord(this.nameSurname)
}

forgotPasswordFunction(){
  this.forgotPassword = true;
}

resetPassword(value) {
  let regexEmail= new RegExp('[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+');
  let regexPassword= new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$');
  if(!value.userNameResetPassword || !value.newPassword || !value.confirmPassword || !regexPassword.test(value.newPassword) || !regexPassword.test(value.confirmPassword) || !regexEmail.test(value.userNameResetPassword) || !(value.newPassword == value.confirmPassword)){
   this.snackBar.open('Të dhëna jo valide!', 'Mbylle', {
         duration: 4000,
       });
  }
  else{
    let newPasswordData = {
      Email : value.userNameResetPassword,
      NewPassword : value.newPassword,
      ConfirmPassword : value.confirmPassword,
    }
    this.loginService.existInAccountsByEmail(newPasswordData.Email).subscribe(accountByEmail => { 
      if(accountByEmail['Id'] != null){
        this.loginService.resetPassword(newPasswordData).subscribe(data => {
          this.snackBar.open(`Ju lutem konfirmojeni ndryshimin e fjalëkalimit në email-in tuaj.`, 'Mbylle', {
            duration: 4000,
            panelClass: ['success-snackbar']
          });          
         })
      }
      else {  
        this.snackBar.open('Nuk ekziston llogari në sistem me këtë email.', 'Mbylle', {
          duration: 3000,
        });
      }
    })
  }
}

capitalizeCaseWord(word: string) {
  if (!word) return word;
  return word.toLocaleLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

determineIsLegalPerson(){
  if(this.userType == 'individ') {
    return false
  }
  else 
    return true
}

determineIsCountryKosova(){
  if(this.country == 'Kosova') {
    return true
  }
  else 
    return false
}

signUp(credentials){
  let regexEmail= new RegExp('[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+');
  let regexPassword= new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$');
  if(!this.nameSurname || !this.personalNumber || !this.email || !this.password || !this.agree || !regexPassword.test(this.password) || !regexEmail.test(this.email)){
   this.snackBar.open('Të dhëna jo valide!', 'Mbylle', {
         duration: 4000,
       });
  }
  else {
    let IsLegalPerson:boolean = this.determineIsLegalPerson();
    let IsCountryKosova:boolean = this.determineIsCountryKosova();
    
    let user = {
      Email : credentials.email,
      FirstName : credentials.nameSurname,
      RoleName : 'User',
      Password : credentials.password,
      ConfirmPassword : credentials.password,
      PersonalNo : credentials.personalNumber,
      IsLegalPerson : IsLegalPerson,
      Kosove : IsCountryKosova
    }

    if(this.determineIsCountryKosova) {
      if(!IsLegalPerson){
        this.loginService.validatePersonalNumber(this.personalNumber).subscribe(isValidPersonalNumber => {
          if(isValidPersonalNumber){
            this.loginService.existInDatabasePersonalNumber(this.personalNumber).subscribe(existPersonalNumber => {
              if(existPersonalNumber['person_id'] != 0) {
                this.loginService.existAccount(1, this.personalNumber).subscribe(accountByPersonalNumber => { 
                  if(accountByPersonalNumber == false){
                    this.loginService.existAccount(2, this.email).subscribe(accountByEmail => { 
                      if(accountByEmail == false){
                        this.loginService.signUp(user).subscribe(data => {
                          this.snackBar.open(`Llogaria u krijua me sukses. \n Ju lutem konfirmoni emailin tuaj`, 'Mbylle', {
                            duration: 4000,
                            panelClass: ['success-snackbar']
                          });  
                         })
                      }
                      else {  
                        this.snackBar.open('Ekziston llogari në sistem me këtë email.', 'Mbylle', {
                          duration: 3000,
                        });
                      }
                    })
                  }
                  else {
                    this.snackBar.open('Ekziston llogari në sistem me këtë numër personal.', 'Mbylle', {
                      duration: 3000,
                    });
                  }
                })
              }
              else{
                this.snackBar.open('Ju nuk jeni klient në Eurosig', 'Mbylle', {
                  duration: 3000,
                });
              }
            })
          }
          else {
            this.snackBar.open('Numri personal nuk është valid', 'Mbylle', {
              duration: 3000,
            });
          }
        })
      }
      else{
        this.loginService.validateBussinesNumber(this.personalNumber).subscribe(isValidBusinessNumber => {
          if(isValidBusinessNumber){
            this.loginService.existInDatabasePersonalNumber(this.personalNumber).subscribe(existPersonalNumber => {
              if(existPersonalNumber['person_id'] != 0) {
                this.loginService.existAccount(1, this.personalNumber).subscribe(accountByPersonalNumber => { 
                  if(accountByPersonalNumber == false){
                    this.loginService.existAccount(2, this.email).subscribe(accountByEmail => { 
                      if(accountByEmail == false){
                        this.loginService.signUp(user).subscribe(data => {
                          this.snackBar.open(`Llogaria u krijua me sukses. \n Ju lutem konfirmoni emailin tuaj`, 'Mbylle', {
                            duration: 4000,
                            panelClass: ['success-snackbar']
                          });             
                         })
                      }
                      else {  
                        this.snackBar.open('Ekziston llogari në sistem me këtë email.', 'Mbylle', {
                          duration: 3000,
                        });
                      }
                    })
                  }
                  else {
                    this.snackBar.open('Ekziston llogari në sistem me këtë numër biznesi.', 'Mbylle', {
                      duration: 3000,
                    });
                  }
                })
              }
              else{
                this.snackBar.open('Ju nuk jeni klient në Eurosig', 'Mbylle', {
                  duration: 3000,
                });
              }
            })
          }
          else {
            this.snackBar.open('Numri i biznesit nuk është valid', 'Mbylle', {
              duration: 3000,
            });
          }
        })
      }
    }
    
  }
 
}

}
