import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseURL="https://api.eurosig-ks.com/apiEurosig/api/accounts/"
  baseURLforLogin="https://api.eurosig-ks.com/apiEurosig/"

  constructor(private http:HttpClient, private router: Router, private localStorageService: LocalStorageService) { }

  headers= new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${this.localStorageService.get('token')}`);

 logIn(credentials) {
  return this.http.post<any>(this.baseURLforLogin + 'oAuth/token', credentials, {'headers':this.headers})
 }

 signUp(credentials){
  let funksioni = "create";
  return this.http.post<any>(this.baseURL + funksioni, credentials, {'headers':this.headers})
 }
 
 existAccount(type, searchField){
  let funksioni = "ExistsUser";
  return this.http.get<any>(this.baseURL + funksioni + "/" + type + "/" + searchField, {'headers':this.headers})
 }

 validatePersonalNumber(personalNumber){
  let funksioni = "Validate_NrPersonal_Kosove";
  return this.http.get<any>(this.baseURL + funksioni + "/" + personalNumber, {'headers':this.headers})
 }

 validateBussinesNumber(bussinesNumber){
  let funksioni = "Validate_NrBiznesit_Kosove";
  return this.http.get<any>(this.baseURL + funksioni + "/" + bussinesNumber, {'headers':this.headers})
 }

 existInDatabasePersonalNumber(personalNumber){
  let funksioni = "ExistPersonalNoInDatabase";
  return this.http.get<any>(this.baseURL + funksioni + "/" + personalNumber, {'headers':this.headers})
 }

 existInAccountsByPersonalNumber(personalNumber){
  let funksioni = "GetUserByPersonalNo";
  return this.http.get<any>(this.baseURL + funksioni + "/" + personalNumber, {'headers':this.headers})
 }

 existInAccountsByEmail(personalNumber){
  let funksioni = "GetUserByEmail";
  return this.http.get<any>(this.baseURL + funksioni + "/" + personalNumber, {'headers':this.headers})
 }

 resetPassword(data){
  let funksioni = "ResetPassword";
  return this.http.post<any>(this.baseURL + funksioni, data, {'headers':this.headers})
 }

 isLoggedIn() { }

}
