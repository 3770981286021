import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { LoginService } from '../shared/services/login.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  public showLoader:boolean = true;
  hide1: boolean = true;
  hide2: boolean = true;
  hide3: boolean = true;
  dataChanged:boolean = false;
  buttonClicked:boolean = false;
  oldPassword:any;
  newPassword:any;
  confirmPassword:any;
  userData:any;
  fullName:string;

  constructor(private userService: UserService, private localStorageService: LocalStorageService, private snackBar: MatSnackBar) { }

  inputChanged(){
    this.dataChanged = true;
  }

  validPassword(password){
    let regexPassword= new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$');
    if(password) {
    return !regexPassword.test(password)
    }
  }

  saveChangePassword() {
    this.buttonClicked = true;
      let regexPassword= new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$');
      if(!this.oldPassword || !this.newPassword || !this.confirmPassword || !regexPassword.test(this.oldPassword) || !regexPassword.test(this.newPassword) || !regexPassword.test(this.confirmPassword) || !(this.newPassword == this.confirmPassword)){
       this.snackBar.open('Të dhëna jo valide!', 'Mbylle', {
             duration: 4000,
           });
      }
      else {
        let changePasswordData = {
          OldPassword : this.oldPassword,
          NewPassword : this.newPassword,
          ConfirmPassword : this.confirmPassword,
      }
        this.userService.changePassword(changePasswordData).subscribe(result =>{
          this.snackBar.open('Fjalëkalimi është ndryshuar me sukses!', 'Mbylle'), {
            duration: 2000,
          };
        },
         (error) => {
          if(error.error.Message == 'The request is invalid.' || error.status == 400) {
            this.snackBar.open('Fjalëkalimi aktual i gabuar!', 'Mbylle'), {
              duration: 2000,
            };
          }
        })
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  ngOnInit(): void {
    this.userService.getUserByUsername(this.localStorageService.get('unique_name')).subscribe(result =>{
      this.userData = (({ UserName, FirstName, Email }) => ({UserName, FirstName, Email }))(result);
        this.fullName = this.userData['FirstName']
        this.showLoader = false;
    });
  }


}
