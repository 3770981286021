import { ProductsService } from './../shared/services/products.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { UserService } from '../shared/services/user.service';
import { ClaimsService } from '../shared/services/claims.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  
  public loggedInUser:any;
  public showLoader:boolean = true;
  activeSelected = true;
  unClosedClaims = false;
  numberOfProdcuts = 10;
  lastProducts = [];
  lastClaims = [];

  toggleProBanner(event) {
    event.preventDefault();
    document.querySelector('body').classList.toggle('removeProbanner');
  }

  constructor(private router:Router, private userService: UserService, private productsService: ProductsService, private claimsService: ClaimsService, private localStorageService: LocalStorageService) { }

  setInsuranceTypeIcon(type){
    switch(type.trim()) { 
      case 'Sigurimi i Pronës nga Zjarri dhe rreziqet plotësuese': { 
         return '../../assets/images/propertyIcon-blue.png'
      }
      case 'Sigurimi i Pronës nga të gjitha rreziqet': { 
        return '../../assets/images/propertyIcon-blue.png'
     } 
      case 'Sigurimi nga Aksidentet Personale - Sigurimi individual': { 
         return '../../assets/images/accidentIcon-blue.png'
      }
      case 'Sigurimi nga Aksidentet Personale - Sigurimi ne Grup': { 
         return '../../assets/images/accidentIcon-blue.png'
      } 
      case 'Sigurimi i Kontraktuesve nga të gjitha rreziqet në ndërtim': { 
         return '../../assets/images/workerIcon-blue.png'
      } 
      case 'Sigurimi i Garancionit të Ofertës': { 
         return '../../assets/images/bidIcon-blue.png'
      }
      case 'Sigurimi i Përgjegjësisë Publike dhe të Produktit': { 
         return '../../assets/images/productIcon-blue.png'
      }
      case 'Sigurimi i Garancionit të Kontratës': { 
         return '../../assets/images/contractIcon-blue.png'
      }
      case 'Sigurimi i Parave në Kasafortë': { 
         return '../../assets/images/safeDepositIcon-blue.png'
      }   
      case 'Sigurimi i Shëndetit në Udhëtim - Koris': { 
         return '../../assets/images/travelIcon-blue.png'
      }      
      case 'Sigurimi i Përgjegjësisë Profesionale': { 
         return '../../assets/images/professionalLiabilityIcon-blue.png'
      } 
      case 'Sigurimi i Garancionit të Avansit-Doganore-Leje Ndertimi-Pagese': { 
         return '../../assets/images/defaultIcon-blue.png'
      }
      case 'Sigurimi i Shëndetit - Sigurimi në Grup': { 
         return '../../assets/images/healthIcon-blue.png'
      } 
      case 'Sigurimi i Shëndetit - Sigurimi Familjar': { 
         return '../../assets/images/healthIcon-blue.png'
      } 
      case 'Sigurimi i Shëndetit - Sigurimi Individual': { 
         return '../../assets/images/healthIcon-blue.png' 
      } 
      case 'Sigurimi i Ruajtjes/Mbajtjes': { 
         return '../../assets/images/contractIcon-blue.png'
      } 
      case 'Sigurimi i Mallërave gjatë transportit - Kargo': { 
         return '../../assets/images/cargoIcon-blue.png'
      } 
      case 'Sigurimi Shtëpiak': { 
         return '../../assets/images/houseIcon-blue.png'
      } 
      case 'Sigurimi i Shëndetit në Udhëtim': { 
         return '../../assets/images/travelIcon-blue.png'
      } 
      case 'Sigurimi i Parave gjatë transportit': { 
         return '../../assets/images/cashIcon-blue.png'
      }  
      case 'Sigurimi TPL Plus': { 
        return '../../assets/images/carIcon-blue.png'
      }
      case 'Sigurimi TPL': { 
        return '../../assets/images/carIcon-blue.png'
      }
      case 'Sigurimi Kasko - Individual': { 
        return '../../assets/images/carIcon-blue.png'
      }   
      case 'BBB & ECC': { 
         return '../../assets/images/defaultIcon-blue.png'
      }   
      default: { 
         return '../../assets/images/defaultIcon-blue.png'
      } 
   } 
  }

  formatDate(date){
    return moment(date).format('DD/MM/YYYY');
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  goToRoute(productId, type){
    if(type == "product") {
      switch(productId) { 
        case 1: { 
          this.router.navigate(['dashboard/products/casco']);
          break; 
        } 
        case 26: { 
          this.router.navigate(['dashboard/products/tpl']);
          break; 
        }
        case 27: { 
          this.router.navigate(['dashboard/products/tpl-plus']);
          break; 
        }
        default: { 
          this.router.navigate(['dashboard/products/others']);
       } 
      }
    }
    else {
      switch(productId) { 
        case 1: { 
          this.router.navigate(['dashboard/claims/casco']);
          break; 
        } 
        case 26: { 
          this.router.navigate(['dashboard/claims/tpl']);
          break; 
        }
        case 27: { 
          this.router.navigate(['dashboard/claims/tpl-plus']);
          break; 
        }
        default: { 
          this.router.navigate(['dashboard/claims/others']);
       } 
      }
    }
  }

  ngOnInit() {
      this.userService.getUserByUsername(this.localStorageService.get('unique_name')).subscribe(result => {
        this.loggedInUser = result['FirstName'].trim();
        this.productsService.getPolicatFundit(this.activeSelected, this.numberOfProdcuts).subscribe(resultProducts => {
          this.lastProducts = resultProducts.map(row => {
            row.Primi_me_tvsh = (Math.round(row.Primi_me_tvsh * 10) / 10).toFixed(1);
            return row;
          })
          this.showLoader = false;
        });
        this.claimsService.getDemetFundit(this.unClosedClaims).subscribe(resultClaims => {
          this.lastClaims = resultClaims.slice(0, 5);
          this.lastClaims.map(element => {
            element.statusi_lendes = element.statusi_lendes.split(",")
            element.trajtuar = (Math.round(element.trajtuar * 10) / 10).toFixed(1);
            return element;
          })
        });
      });
  }

  navigateToProfile(){
    this.router.navigate(['user-profile'])
  }

}
