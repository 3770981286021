import { UserService } from 'src/app/shared/services/user.service';
import { HealthService } from '../shared/services/health.service';
import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Component({
  selector: 'app-health-claims',
  templateUrl: './health-claims.component.html',
  styleUrls: ['./health-claims.component.scss']
})
export class HealthClaimsComponent implements OnInit {

  public showLoader:boolean = true;
  public windowWidth:any;
  healthInsClaimsDetails:any;
  openedArray: boolean[]= [false];
  openedInvoice:boolean=false;
  openedClaimTreat:boolean=false;
  hasFamilyMembers:boolean=false;
  familyMembersArray:any = [];
  cardId:any;
  personalNo:any;
  secondDate = new Date();
  firstDate = new Date((new Date().getTime() - 315576000000));
  date1= this.firstDate.getFullYear() +"-"+ this.addZero(this.firstDate.getMonth()+1) + "-"+ this.addZero(this.firstDate.getDate());
  date2= this.secondDate.getFullYear() +"-"+ this.addZero(this.secondDate.getMonth()+1) + "-"+ this.addZero(this.secondDate.getDate());

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.windowWidth = event.target.innerWidth;
  }

  constructor(private healthService: HealthService, private userService: UserService, private localStorageService:LocalStorageService) { 
    this.personalNo = this.localStorageService.get('personal_number');
  }

  periods: any[] = [
    {value: '1', viewValue: "7 ditët e fundit"},
    {value: '2', viewValue: "30 ditët e fundit"},
    {value: '3', viewValue: "6 muajt e fundit"},
    {value: '4', viewValue: "Viti i fundit"},
    {value: '5', viewValue: "Gjithë kohës"},
  ];

  filterDate = this.periods[4].value;
  filterFamilyMember;

  filterPeriods(value){
    if(this.hasFamilyMembers){
      this.filterFamilyMember = this.familyMembersArray[0].value;
    }
    switch(value) { 
      case '1': { 
        this.firstDate = new Date((new Date().getTime() - 604800016.55));
        this.secondDate = new Date();
        this.date1 = this.firstDate.getFullYear() + "-" + this.addZero(this.firstDate.getMonth()+1) + "-" + this.addZero(this.firstDate.getDate());
        this.date2 = this.secondDate.getFullYear() + "-" + this.addZero(this.secondDate.getMonth()+1) + "-" + this.addZero(this.secondDate.getDate());
        this.showLoader = true;
        this.initializeData();
        break; 
      } 
      case '2': { 
        this.firstDate = new Date((new Date().getTime() - 2629800000));
        this.secondDate = new Date();
        this.date1 = this.firstDate.getFullYear() + "-" + this.addZero(this.firstDate.getMonth()+1) + "-" + this.addZero(this.firstDate.getDate());
        this.date2 = this.secondDate.getFullYear() + "-" + this.addZero(this.secondDate.getMonth()+1) + "-" + this.addZero(this.secondDate.getDate());
        this.showLoader = true;
        this.initializeData();
        break; 
      } 
      case '3': { 
        this.firstDate = new Date((new Date().getTime() - 15778800000 ));
        this.secondDate = new Date();
        this.date1 = this.firstDate.getFullYear() + "-" + this.addZero(this.firstDate.getMonth()+1) + "-" + this.addZero(this.firstDate.getDate());
        this.date2 = this.secondDate.getFullYear() + "-" + this.addZero(this.secondDate.getMonth()+1) + "-" + this.addZero(this.secondDate.getDate());
        this.showLoader = true;
        this.initializeData();
        break; 
     } 
      case '4': { 
        this.firstDate = new Date((new Date().getTime() -  31557600000));
        this.secondDate = new Date();
        this.date1 = this.firstDate.getFullYear() + "-" + this.addZero(this.firstDate.getMonth()+1) + "-" + this.addZero(this.firstDate.getDate());
        this.date2 = this.secondDate.getFullYear() + "-" + this.addZero(this.secondDate.getMonth()+1) + "-" + this.addZero(this.secondDate.getDate());
        this.showLoader = true;
        this.initializeData();
        break; 
      } 
      case '5': { 
        this.firstDate = new Date((new Date().getTime() - 315576000000 ));
        this.secondDate = new Date();
        this.date1 = this.firstDate.getFullYear() + "-" + this.addZero(this.firstDate.getMonth()+1) + "-" + this.addZero(this.firstDate.getDate());
        this.date2 = this.secondDate.getFullYear() + "-" + this.addZero(this.secondDate.getMonth()+1) + "-" + this.addZero(this.secondDate.getDate());
        this.showLoader = true;
        this.initializeData();
        break; 
      } 
      default: { 
        this.firstDate = new Date((new Date().getTime() - 315576000000 ));
        this.secondDate = new Date();
        this.date1 = this.firstDate.getFullYear() + "-" + this.addZero(this.firstDate.getMonth()+1) + "-" + this.addZero(this.firstDate.getDate());
        this.date2 = this.secondDate.getFullYear() + "-" + this.addZero(this.secondDate.getMonth()+1) + "-" + this.addZero(this.secondDate.getDate());
        this.showLoader = true;
        this.initializeData(); 
         break; 
      } 
    }
  }

  filterFamilyMembers(filteredUserId){
    this.cardId = filteredUserId;
    this.showLoader = true;
    this.healthService.getRequestsForClaims(this.cardId, this.date1, this.date2).subscribe(result => {
      this.healthInsClaimsDetails = result.map(row => {
        row.Paguar = (Math.round(row.Paguar * 100) / 100).toFixed(2);
        row.VleraFatures = (Math.round(row.VleraFatures * 100) / 100).toFixed(2);
        if(row.VleraTrajtimit) {
          row.VleraTrajtimit = (Math.round(row.VleraTrajtimit * 100) / 100).toFixed(2);
        }
        return row;
      })
    this.openedArray.length = this.healthInsClaimsDetails.length
    this.openedArray.fill(false)
    this.showLoader = false
    })
  }

  openClaim(index) {
    this.openedArray[index] = !this.openedArray[index]
    this.openedArray = this.openedArray.map((el, i) => {
      if(i != index) el = false;
      return el;
    });
    this.openedInvoice = false;
    this.openedClaimTreat = false;
  }
  
  setBackgroundColor(index){
      if (index % 2 == 0) 
        return '#FFFFFF';
      else 
        return '#F8F9FA';
  }

  addZero (value) {
    if (String(value).length == 1)
      return "0" + value
    else
      return value
  }

  initializeData(){
    this.userService.getHealthInsurance(this.personalNo).subscribe(data =>{
      this.cardId = data['cardId'];
      this.healthService.getRequestsForClaims(this.cardId, this.date1, this.date2).subscribe(result => {
        this.healthInsClaimsDetails = result.map(row => {
          row.Paguar = (Math.round(row.Paguar * 100) / 100).toFixed(2);
          row.VleraFatures = (Math.round(row.VleraFatures * 100) / 100).toFixed(2);
          if(row.VleraTrajtimit) {
            row.VleraTrajtimit = (Math.round(row.VleraTrajtimit * 100) / 100).toFixed(2);
          }
          return row;
        })
      this.openedArray.length = this.healthInsClaimsDetails.length;
      this.openedArray.fill(false);
      this.showLoader = false;
      })
    });
  }

  formatDate(date){
    return moment(date).format('DD/MM/YYYY');
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  getFamilyMembers(){
    this.userService.getHealthInsurance(this.personalNo).subscribe(data =>{
      this.cardId = data['cardId'];
      this.healthService.getFamiljaretPerKartele(this.cardId).subscribe(result => {
        if(result.length > 1) {
          this.hasFamilyMembers = true;
          result.map((element) => { 
            let tempMember = { value: element.cardId, viewValue: element.CardName }
            this.familyMembersArray.push(tempMember)
          })
          this.filterFamilyMember = this.familyMembersArray[0].value;
        }
      })
    })
  }
  
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.initializeData();
    this.getFamilyMembers();
  }

}