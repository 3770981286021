import { LoginService } from './../services/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JWTTokenService } from '../services/jwttoken.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuardGuard {

  constructor(private loginService: LoginService,
    public router: Router,
    private localStorageService: LocalStorageService,
    private jwtService: JWTTokenService) {
}

canActivate(): boolean {
  if (this.jwtService.isTokenExpired() || this.localStorageService.get("token") === null) {
    this.router.navigate(['login']);
    return false;
  }
  return true;
}
  
}
