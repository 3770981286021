import { Component, HostListener, OnInit } from '@angular/core';
import { ProductsService } from '../shared/services/products.service';

@Component({
  selector: 'app-tpl-plus-product',
  templateUrl: './tpl-plus-product.component.html',
  styleUrls: ['./tpl-plus-product.component.scss']
})
export class TplPlusProductComponent implements OnInit {
  
  public showLoader:boolean = true;
  public windowWidth:any;
  tplPlusProductsDetails:any;
  activeSelected = true;
  allSelected = false;
  platesSelected = false;
  plateInput:any=null;
  openedArray: boolean[]= [false];
  openedCarDetails:boolean=false;
  openedPolicyDetails:boolean=false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.windowWidth = event.target.innerWidth;
  }
  
  constructor(private productsService: ProductsService) { }

  openClaim(index) {
    this.openedArray[index] = !this.openedArray[index]
    this.openedArray = this.openedArray.map((el, i) => {
      if(i != index) el = false;
      return el;
    });
    this.openedCarDetails = false;
    this.openedPolicyDetails = false;
  }
  
  setBackgroundColor(index){
      if (index % 2 == 0) 
        return '#FFFFFF';
      else 
        return '#F8F9FA';
  }

  setVehicleIcon(lloji){
    if(lloji == 'AUTOMJETET E UDHËTARËVE') {
      return '../../assets/images/carIcon-blue.png'
    }
    else if(lloji == 'AUTOMJETET BARTËSE, KAMIONËT') {
      return '../../assets/images/carIcon-blue.png'
    }
    else if(lloji == 'AUTOBUSËT URBAN' || lloji == 'AUTOBUSËT NDËRURBAN') {
      return '../../assets/images/truckIcon-blue.png'
    }
    else if(lloji == 'TRAKTORËT ME OBLIGIM REGJISTRIMI') {
      return '../../assets/images/tractorIcon-blue.png'
    }
    else if(lloji == 'MOTOÇIKLETAT') {
      return '../../assets/images/motorcycleIcon-blue.png'
    }
    else if(lloji == 'RIMORKIOT') {
      return '../../assets/images/trailerIcon-blue.png'
    }
    else {
      return '../../assets/images/carIcon-blue.png'
    }
  }

  selectChanged(checked){
    if(checked == true) {
      this.activeSelected = true;
      this.allSelected = false;
    }
    else {
      this.activeSelected = false;
      this.allSelected = true;
    }
    this.initializeData();
  }

  onSearchChange(){
    if(this.plateInput.length > 0) {
      this.platesSelected = true;
    }
    else {
      this.platesSelected = false;
      this.plateInput = null;
    }
    this.initializeData();
  }

  initializeData(){
    this.productsService.getPolicatTPLPlus(this.allSelected, this.platesSelected, this.plateInput, this.activeSelected).subscribe(result => {
      this.tplPlusProductsDetails = result;
      this.openedArray.length = this.tplPlusProductsDetails.length;
      this.openedArray.fill(false);
      this.showLoader = false;
    })
  }
  
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.initializeData();
  }

}
