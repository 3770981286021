import { UserService } from 'src/app/shared/services/user.service';
import { HealthService } from '../shared/services/health.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Component({
  selector: 'app-health-territories-coverage',
  templateUrl: './health-territories-coverage.component.html',
  styleUrls: ['./health-territories-coverage.component.scss']
})
export class HealthTerritoriesCoverageComponent implements OnInit {
  
  public showLoader:boolean = true;
  cardId:any;
  personalNo:any;
  healthInsTerritories:any;
  
  constructor(private healthService: HealthService, private userService: UserService, private localStorageService:LocalStorageService) {
    this.personalNo = this.localStorageService.get('personal_number');
   }

  setBackgroundColor(index){
    if (index % 2 == 0) 
      return '#FFFFFF';
    else 
      return '#F8F9FA';
  }

  ngOnInit(): void {
    this.userService.getHealthInsurance(this.personalNo).subscribe(data =>{
      this.cardId = data['cardId'];
      this.healthService.getCoverageTerritories(this.cardId).subscribe(result => {
        this.healthInsTerritories=result;
        this.showLoader = false;
      });
    });
  }

}
