import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public baseURL1="https://api.eurosig-ks.com/apiEurosig/api/accounts";
  public headers:HttpHeaders;

  constructor(private http:HttpClient, private router: Router, private localStorageService: LocalStorageService) { }

  setHeaders(){
    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', 'Bearer ' + this.localStorageService.get('token'));
  }

 getUserByUsername(username) {
  this.setHeaders();
  return this.http.get<any>(`${this.baseURL1}/user/${username}`, {'headers':this.headers})
 }
 getUserById(id) {
  this.setHeaders();
  return this.http.get<any>(`${this.baseURL1}/user/${id}`, {'headers':this.headers})
 }
 getCountries(language){
  this.setHeaders();
  let funksioni = "GetMainStates"; 
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${language}`, {'headers':this.headers})
 }
 getHealthInsurance(personalNumber) {
  this.setHeaders();
  let funksioni = "GetHealthCardIdInfo";
  return this.http.get<any>(`${this.baseURL1}/${funksioni}/${personalNumber}`, {'headers':this.headers})
}
 changeUserProfileDetails(data) {
  this.setHeaders();
  let funksioni = "ChangeUserProfile";
  return this.http.post<any>(`${this.baseURL1}/${funksioni}`, data, {'headers':this.headers})
 }
 changePassword(data){
  this.setHeaders();
  let funksioni = "ChangePassword";
  return this.http.post<any>(`${this.baseURL1}/${funksioni}`, data, {'headers':this.headers})
 }
}