import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ClaimsService } from '../shared/services/claims.service';

@Component({
  selector: 'app-casco-claim',
  templateUrl: './casco-claim.component.html',
  styleUrls: ['./casco-claim.component.scss']
})
export class CascoClaimComponent implements OnInit {
  
  public showLoader:boolean = true;
  public windowWidth:any;
  cascoClaimsDetails:any;
  activeSelected = true;
  allSelected = false;
  platesSelected = false;
  plateInput:any=null;
  openedArray: boolean[]= [false];
  openedCarDetails:boolean=false;
  openedPolicyDetails:boolean=false;
  openedReviewDetails:boolean=false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.windowWidth = event.target.innerWidth;
  }
  
  constructor(private claimService: ClaimsService) { }

  openClaim(index) {
    this.openedArray[index] = !this.openedArray[index]
    this.openedArray = this.openedArray.map((el, i) => {
      if(i != index) el = false;
      return el;
    });
    this.openedCarDetails = false;
    this.openedPolicyDetails = false;
    this.openedReviewDetails = false;
  }
  
  setBackgroundColor(index){
      if (index % 2 == 0) 
        return '#FFFFFF';
      else 
        return '#F8F9FA';
  }

  selectChanged(checked){
    if(checked == true) {
      this.activeSelected = true;
      this.allSelected = false;
    }
    else {
      this.activeSelected = false;
      this.allSelected = true;
    }
    this.initializeData();
  }

  setVehicleIcon(lloji){
    if(lloji == 'AUTOMJETET E UDHËTARËVE') {
      return '../../assets/images/carIcon-blue.png'
    }
    else if(lloji == 'AUTOMJETET BARTËSE, KAMIONËT') {
      return '../../assets/images/carIcon-blue.png'
    }
    else if(lloji == 'AUTOBUSËT URBAN' || lloji == 'AUTOBUSËT NDËRURBAN') {
      return '../../assets/images/truckIcon-blue.png'
    }
    else if(lloji == 'TRAKTORËT ME OBLIGIM REGJISTRIMI') {
      return '../../assets/images/tractorIcon-blue.png'
    }
    else if(lloji == 'MOTOÇIKLETAT') {
      return '../../assets/images/motorcycleIcon-blue.png'
    }
    else if(lloji == 'RIMORKIOT') {
      return '../../assets/images/trailerIcon-blue.png'
    }
    else {
      return '../../assets/images/carIcon-blue.png'
    }
  }

  onSearchChange(){
    if(this.plateInput.length > 0) {
      this.platesSelected = true;
    }
    else {
      this.platesSelected = false;
      this.plateInput = null;
    }
    this.initializeData();
  }

  initializeData(){
    this.claimService.getDemetCasco(this.allSelected, this.platesSelected, this.plateInput, this.activeSelected).subscribe(result => {
      this.cascoClaimsDetails = result;
      this.cascoClaimsDetails.map(element => {
        element.statusi_lendes = element.statusi_lendes.split(",");
      })
      this.openedArray.length = this.cascoClaimsDetails.length;
      this.openedArray.fill(false);
      this.showLoader = false;

    })
  }

  formatDate(date){
    return moment(date).format('DD/MM/YYYY');
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.initializeData();
  }

}
