import { LocalStorageService } from './../services/local-storage.service';
import { UserService } from './../services/user.service';
import { SharedService } from './../services/shared.service';
import { Component, Inject, OnInit } from '@angular/core';
import { HealthService } from '../services/health.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public products = false;
  public claims = false;
  public health = false;
  public samplePagesCollapsed = false;
  public cardNoForHealthInsurance:any;
  public loggedInUser:any;
  public personalNo:any;
  
  constructor(private healthService: HealthService, private userService: UserService, private localStorageService: LocalStorageService) { }

  closeOtherTabs(type){
    if(type == 'health'){
      this.claims = false;
      this.products = false;
    }
    else if(type == 'claims') {
      this.health = false;
      this.products = false;
    }
    else{
      this.claims = false;
      this.health = false;
    }
  }

  goToHomePage() {
    window.open('https://www.eurosig-ks.com', "_blank");
  }

  closeSideBar(){
    const sidebar = document.querySelector('#sidebar');
    sidebar.classList.remove("active");
  }

  ngOnInit() {
    
    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

      this.userService.getUserByUsername(this.localStorageService.get('unique_name')).subscribe(result => {
        this.loggedInUser = result['FirstName'].trim();
        this.personalNo = this.localStorageService.get('personal_number');
        this.userService.getHealthInsurance(this.personalNo).subscribe(data =>{
          this.cardNoForHealthInsurance = data['cardNo'];
        });
      });
  }

}
